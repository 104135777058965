@import '../../../theme/helper.scss';

.modalWrapper {
    @media screen and (min-width: $mobile-breakpoint) {
        --border-radius: 0.625rem;

        &.tall {
            --height: 80vh;

            :global(.modal-wrapper) {
                min-height: 36.25rem;
                max-height: 61.25rem;
            }
        }

        &.medium {
            --height: 65vh;

            :global(.modal-wrapper) {
                min-height: 36.25rem;
                max-height: 45rem;
            }
        }

        &.short {
            --height: 45vh;

            :global(.modal-wrapper) {
                min-height: 25rem;
                max-height: 31.25rem;
            }
        }
    }

    .container {
        &::part(scroll) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .closeBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: fixed;
            top: 0.9rem;
            right: 0.9rem;
            z-index: 1;
            opacity: 0.7;
            transition: opacity 120ms ease-out;
            font-size: 1.8rem;

            &:hover {
                opacity: 1;
            }

            @mixin closebtn-responsive {
                margin-top: env(safe-area-inset-top);
                position: relative;
                top: 0;
                right: 0;
                left: 0;
                justify-content: flex-end;
                margin-right: 0.9rem;
            }

            @media only screen and (max-width: $mobile-breakpoint) {
                @include closebtn-responsive;
            }

            &-mobile {
                @include closebtn-responsive;
            }
        }

        .modalTitle {
            font-weight: bold;
            font-size: 2rem;
            line-height: 3rem;
            text-align: center;

            @mixin modalTitle-responsive {
                margin-top: 1rem;
            }

            @media only screen and (max-width: $mobile-breakpoint) {
                @include modalTitle-responsive;
            }

            &-mobile {
                @include modalTitle-responsive;
            }
        }

        .subTitle {
            font-size: 1rem;
            text-align: center;
            max-width: 21rem;
            margin: 0 auto 1rem;
            padding: 0 0.5rem;
        }

        .cardWrapper {
            margin: 0 auto;
            padding: 0 5rem;
            width: 100%;
        }
    }
}
