.repImage {
    --border-radius: 50%;

    position: relative;
    width: 4.6875rem;
    height: 4.6875rem;
}

.ionIcon {
    color: var(--ion-color-primary);
    font-size: 3rem;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 120ms ease;

    &:hover {
        opacity: 0.7;
    }
}
