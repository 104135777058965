.static {
    position: fixed;
}

.fabExpanded {
    display: flex;
    align-items: center;

    /* stylelint-disable selector-type-no-unknown */
    ion-button {
        color: #fff;
        text-transform: none;
        font-size: 16px;
        padding: 0 1em 0 0;
        max-width: fit-content;
    }

    ion-fab-button {
        min-width: 56px;
    }
    /* stylelint-enable selector-type-no-unknown */
}

.fabOpenButton {
    min-width: 56px;
}
