@import '../../../theme/helper.scss';

.modalWrapper {
    @media only screen and (min-width: $mobile-breakpoint) {
        --border-radius: 0.625rem;

        :global(.modal-wrapper) {
            max-height: 28.75rem;
        }
    }

    .main {
        border-bottom: 0.625rem solid var(--ion-color-light-contrast);
    }

    .contacts {
        margin: 2rem 2rem;
    }

    .item {
        background: var(--background);
        padding: 0 2rem 1rem 2rem;
        border-bottom: 0.0625rem solid var(--ion-color-light-tint);
        display: flex;

        @mixin item-responsive {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            @include item-responsive;
        }

        &-mobile {
            @include item-responsive;
        }
    }

    .container {
        --padding-top: 1.2rem;
        --padding-bottom: 3rem;

        .item-native .item-inner {
            padding: 0;
        }

        .logoContainer {
            padding: 0.5rem 0 0 0;

            .logo {
                font-size: 1.5rem;
                padding-right: 0.5rem;
            }

            .routerLink {
                a {
                    outline: none;
                }
            }
        }

        .closeBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: fixed;
            top: 0.9rem;
            right: 0.9rem;
            z-index: 1;
            opacity: 0.7;
            transition: opacity 120ms ease-out;
            font-size: 1.8rem;

            &:hover {
                opacity: 1;
            }

            @mixin closeBtn-responsive {
                margin-top: env(safe-area-inset-top);
            }

            @media only screen and (max-width: $mobile-breakpoint) {
                @include closeBtn-responsive;
            }

            &-mobile {
                @include closeBtn-responsive;
            }
        }

        .repImage {
            --border-radius: 50%;

            min-width: 4.6875rem;
            width: 4.6875rem;
            height: 4.6875rem;
        }

        .text {
            margin: 0 5%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .name {
                font-size: 1.25rem;
                font-weight: bold;
                margin-top: 0;
                margin-bottom: 0;

                @mixin name-responsive {
                    font-size: 1rem;
                }

                @media only screen and (max-width: $mobile-breakpoint) {
                    @include name-responsive;
                }

                &-mobile {
                    @include name-responsive;
                }
            }

            .title {
                font-size: 0.875rem;
            }
        }

        .modalTitle {
            margin: 2em auto 0.5em;
            font-weight: bold;
            font-size: 2rem;
            line-height: 3rem;
            text-align: center;
        }

        .subTitle {
            font-size: 1rem;
            text-align: center;
            max-width: 21rem;
            margin: 0 auto 1rem;
            padding: 0 0.5rem;
        }

        .cardWrapper {
            margin: 0 auto;
            max-width: 18.75rem;
            width: 100%;
        }

        @mixin container-responsive {
            margin-top: env(safe-area-inset-top);
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            @include container-responsive;
        }

        &-mobile {
            @include container-responsive;
        }
    }
}
