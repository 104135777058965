.inputContainer {
    width: 100%;

    input {
        filter: none;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset;
            -webkit-box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset;
            -webkit-text-fill-color: black;
        }
    }

    .inputFieldWrapper {
        display: flex;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;

        &.outline {
            border: 0.0625rem solid var(--ion-color-medium-shade);
        }

        @mixin borderless {
            border: none;
        }

        &.primary {
            @include borderless;

            border-bottom: 0.125rem solid var(--ion-color-primary);
        }

        &.secondary {
            @include borderless;

            border-bottom: 0.125rem solid var(--ion-color-secondary);
        }

        .label {
            display: block;
            font-size: 1rem;
            line-height: 1;
            margin-bottom: 0.5rem;
        }

        .input {
            --padding-start: 0.5rem;
            --padding-top: 0.65rem;
            --padding-bottom: 0.5rem;

            &[type="password"] label {
                padding-top: 0;
                padding-bottom: 0;
            }

            :global(.native-input) {
                border-radius: 0;
            }

            &.withIcon {
                display: inline-flex;
            }

            &.disabled {
                background-color: #ddd;

                :global(.native-input) {
                    cursor: not-allowed;
                }
            }
        }

        .iconBtn {
            position: relative;
            display: inline-flex;
            padding: 0.6rem 0.32rem;
            background-color: #fff;
            font-size: 1.3rem;
            outline: none;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-left: 1px solid #f4f4f4;
            color: #000;

            &:focus {
                outline: 2px solid #007bff;  /* A clear outline color */
                background-color: #e9ecef;   /* Subtle background color */
            }
        }

        .xIcon {
            border-radius: 50%;
            position: relative;
            color: rgb(79, 75, 74);
            background-color: white;
            text-align: center;
            right: 1rem;
            top: 0.8rem;
            z-index: 1000;
            cursor: pointer;
        }
    }

    span.input-footer-copy {
        font-size: 0.75rem;
        position: relative;
        line-height: 1;
        top: 0.18rem;

        &.error {
            font-weight: bold;
            color: var(--ion-color-danger-shade);

            &::before {
                content: '⚠ ';
                vertical-align: sub;
                font-size: 1.15rem;
            }
        }
    }
}
