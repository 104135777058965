@import '../../theme/helper.scss';

.inputWrapper {
    border-bottom: 0.125rem solid var(--ion-color-primary);
    border-radius: 0.25rem;
}

.jurisdictionLabels {
    display: flex;
    flex-wrap: wrap; // Ensures that the items wrap when they run out of space
    justify-content: flex-start; // Aligns the items to the start of the container
    gap: 10px; // Adds space between each label
    margin-top: 10px;
    width: 100%; // Ensures the container takes the full width of the page
}

.jurisdictionLabel {
    display: flex; // Ensure the icon and text are aligned horizontally
    align-items: center; // Align the items vertically
    background-color: #f9f9f9;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    white-space: normal; // Prevent text from wrapping within each label
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex: 0 1 auto; // Ensure the label takes up space according to its content
    word-wrap: break-word; // Prevent long words from breaking the layout
    max-width: 100%; // Prevent overflow in case the content is long
}

.labelIcon {
    margin-right: 8px;
    font-size: 18px;
}

.labelText {
    font-weight: bold;
    color: #333;
}

.jurisdictionButton {
    margin-top: 0.5em;
}

.jurisdictionMobileNav {
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.75rem;
    font-weight: bold;
    margin: 0 auto;

    .navTitle {
        color: var(--ion-color-medium-shade);
        background: var(--ion-color-tertiary);
        padding: 0.8125rem;

        &.active {
            color: var(--ion-color-primary);
            border-bottom: 0.0625rem solid var(--ion-text-color);
        }
    }

    &-desktop {
        display: none;
    }
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    overflow: auto;

    &-mobile {
        margin-top: calc(6.5rem);
    }

    .contentSection {
        position: relative;
        z-index: 0;
        max-width: 90rem;
        width: 100%;

        @mixin contentSection-responsive {
            max-width: 31.25rem;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            @include contentSection-responsive;
        }

        &-mobile {
            @include contentSection-responsive;
        }

        .sectionHeader {
            font-weight: bold;
            margin: 1.5rem 0 0.25rem;
            color: var(--ion-text-color);

            @media only screen and (max-width: $mobile-breakpoint) {
                margin-bottom: 0.5rem;
            }
        }

        .repCardsContainer {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;

            @mixin repCardsContainer-responsive {
                justify-content: center;
            }

            @media only screen and (max-width: $mobile-breakpoint) {
                @include repCardsContainer-responsive;
            }

            &-mobile {
                @include repCardsContainer-responsive;
            }

            .card {
                max-width: 30%;
                margin: 1rem;
                flex: 1 0 30%;
                background: var(--ion-color-tertiary);
                border-radius: 0.5rem;
                box-shadow: 0 0.25rem 0.875rem rgba(var(--ion-box-shadow-rgb), 0.75);

                @media only screen and (max-width: $wide-tablet-breakpoint) {
                    min-width: 45%;
                }

                @mixin card-responsive {
                    min-width: 62%;
                    max-width: 100%;
                    margin: 0.4rem 0;
                }

                @media only screen and (max-width: $mobile-breakpoint) {
                    @include card-responsive;
                }

                &-mobile {
                    @include card-responsive;
                }

                .cardTitle {
                    font-weight: bold;

                    --color: var(--ion-text-color);
                }

                .cardSubtitle {
                    --color: var(--ion-text-color);
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    :global(.ios) {
        :global(.iosMyrepsContentSection) {
            margin: 1.25rem auto 0 auto;
        }
    }
}
