@import '../../../theme/helper.scss';

.subscriberForm {
    width: 40rem;
    max-width: 100%;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    justify-content: center;

    & > div {
        @media only screen and (min-width: $wide-tablet-breakpoint) {
            margin: 0 1rem 0 0;
            max-width: 65%;
        }
    }
}
