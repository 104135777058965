@import '../../../theme/helper.scss';

@media all and (min-width: $mobile-breakpoint) {
    .desktopNav {
        display: flex;
        z-index: 10;
        justify-content: space-between;
        width: 100%;
        padding: 0.7rem 1rem 0.4rem;
        background-color: var(--ion-color-primary-contrast);
        font-size: 0.875rem;
        min-height: 3.4375rem;

        .linksContainer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .menu {
            display: flex;
            align-items: center;
            min-width: 12.5rem;
            padding: 0 1rem;
            list-style-type: none;

            .active {
                padding: 0 0 1rem 0;
                border-bottom: 0.0625rem solid var(--ion-color-dark-tint);
            }

            .item {
                order: 2;
                width: 100%;
                margin: 0 0.85rem;
                padding: 0 0 0.7rem 0;
                color: var(--ion-color-dark-tint);
                text-align: center;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}

@media all and (max-width: $mobile-breakpoint) {
    .desktopNav {
        display: none;
    }
}

.signUp {
    font-weight: normal !important;
}
