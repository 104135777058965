.container {
    display: flex;
    flex-direction: column;
    max-width: 24.5625rem;
    padding: 0.7rem 0.7rem 2rem 0.7rem;
    box-sizing: border-box;

    .chevronIcon {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: var(--ion-text-color);
    }

    .text {
        margin-top: 0.8rem;
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;
        line-height: 150%;
        color: var(--ion-color-step-250);

        .name {
            margin: 0.5rem 0 0.3rem;
            color: var(--ion-text-color);
            font-weight: 700;
            font-size: 1.75rem;
        }

        .title {
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 140%;
            color: var(--ion-color-step-300);
        }

        .party {
            margin: 0.7rem 0 0.3rem;
        }
    }
}
