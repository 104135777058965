@import '../../theme/helper.scss';

.container {
    padding: 5% 0;

    @mixin container-responsive {
        padding: 1.25rem 0;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include container-responsive;
    }

    &-mobile {
        @include container-responsive;
    }

    .content {
        display: flex;
        flex-direction: column;
        max-width: 45rem;
        margin: 0 auto;
        font-size: 1rem;
        box-shadow: 0 0.25rem 0.875rem rgba(var(--ion-box-shadow-rgb), 0.75);
        background-color: var(--ion-color-tertiary);
        color: var(--ion-text-color);
        border-radius: 0.5rem;
        padding: 3.125rem;

        p {
            margin: 0.625rem 0;
        }

        .heading {
            color: var(--ion-color-step-250);

            &:first-child {
                margin-bottom: 0;
            }
        }

        .subheading {
            margin-top: 0;
        }

        @mixin content-responsive {
            padding: 1rem;
            margin: 0;
            max-width: 100%;
            border-radius: 0;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            @include content-responsive;
        }

        &-mobile {
            @include content-responsive;
        }
    }
}
