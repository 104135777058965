.container {
    max-width: 26.25rem;
    margin: 0 auto;
    text-align: center;

    p {
        margin-bottom: 1rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.resendLink {
    text-decoration: underline;
    cursor: pointer;
    color: var(--ion-color-primary);
}
