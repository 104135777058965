/* stylelint-disable */
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Apercu Pro Font **/
@font-face {
    font-family: 'Apercu Pro';
    src: local('Apercu Pro'), local('ApercuPro'), url('../assets/fonts/ApercuPro.woff2') format('woff2'),
        url('../assets/fonts/ApercuPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Pro';
    src: local('Apercu Pro Bold'), local('ApercuPro-Bold'), url('../assets/fonts/ApercuPro-Bold.woff2') format('woff2'),
        url('../assets/fonts/ApercuPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #0c6055;
    --ion-color-primary-rgb: rgb(12, 96, 85);
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #56c1c1;
    --ion-color-primary-tint: #5598ff;
    --ion-color-primary-low-opacity: #84aea9;

    /** secondary **/
    --ion-color-secondary: #ffb46d;
    --ion-color-secondary-rgb: rgb(255, 180, 109);
    --ion-color-secondary-contrast: #403b3a;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #f7be8a;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #ffffff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #333;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #fbfbfb;
    --ion-color-tertiary-tint: #fbfbfb;

    /** success **/
    --ion-color-success: #265e64;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #fff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #4f4b4a;

    /** warning **/
    --ion-color-warning: #ffc58e;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #e87c2d;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffe3e3;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #9a0000;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #fefefe;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #b2b1af;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #fff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #ededed;
    --ion-color-light-tint: #e6e6e6;

    --ion-font-family: 'Apercu Pro', Helvetica, Arial, sans-serif;
    --ion-text-color: #373433;

    --ion-box-shadow-rgb: 237, 237, 237;
}

@media only screen and (prefers-color-scheme: dark) {
    /*
   * Dark Colors
   * -------------------------------------------
   */

    body {
        /** primary **/
        --ion-color-primary: #0c6055;
        --ion-color-primary-rgb: rgb(12, 96, 85);
        --ion-color-primary-contrast: #fff;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-color-primary-shade: #56c1c1;
        --ion-color-primary-tint: #5598ff;

        /** secondary **/
        --ion-color-secondary: #ffb46d;
        --ion-color-secondary-rgb: rgb(255, 180, 109);
        --ion-color-secondary-contrast: #403b3a;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #f7be8a;
        --ion-color-secondary-tint: #50c8ff;

        /** tertiary **/
        --ion-color-tertiary: #ffffff;
        --ion-color-tertiary-rgb: 82, 96, 255;
        --ion-color-tertiary-contrast: #333;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #fbfbfb;
        --ion-color-tertiary-tint: #fbfbfb;

        /** success **/
        --ion-color-success: #265e64;
        --ion-color-success-rgb: 45, 211, 111;
        --ion-color-success-contrast: #fff;
        --ion-color-success-contrast-rgb: 255, 255, 255;
        --ion-color-success-shade: #28ba62;
        --ion-color-success-tint: #4f4b4a;

        /** warning **/
        --ion-color-warning: #ffc58e;
        --ion-color-warning-rgb: 255, 196, 9;
        --ion-color-warning-contrast: #000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0ac08;
        --ion-color-warning-tint: #e87c2d;

        /** danger **/
        --ion-color-danger: #eb445a;
        --ion-color-danger-rgb: 235, 68, 90;
        --ion-color-danger-contrast: #ffe3e3;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #9a0000;
        --ion-color-danger-tint: #ed576b;

        /** dark **/
        --ion-color-dark: #222428;
        --ion-color-dark-rgb: 34, 36, 40;
        --ion-color-dark-contrast: #fefefe;
        --ion-color-dark-contrast-rgb: 255, 255, 255;
        --ion-color-dark-shade: #1e2023;
        --ion-color-dark-tint: #383a3e;

        /** medium **/
        --ion-color-medium: #b2b1af;
        --ion-color-medium-rgb: 146, 148, 156;
        --ion-color-medium-contrast: #fff;
        --ion-color-medium-contrast-rgb: 255, 255, 255;
        --ion-color-medium-shade: #808289;
        --ion-color-medium-tint: #9d9fa6;

        /** light **/
        --ion-color-light: #f4f5f8;
        --ion-color-light-rgb: 244, 245, 248;
        --ion-color-light-contrast: #000;
        --ion-color-light-contrast-rgb: 0, 0, 0;
        --ion-color-light-shade: #d7d8da;
        --ion-color-light-tint: #e6e6e6;

        --ion-font-family: 'Apercu Pro', Helvetica, Arial, sans-serif;
        --ion-text-color: #373433;

        --ion-box-shadow-rgb: 237, 237, 237;
    }

    /*
   * iOS Dark Theme
   * -------------------------------------------
   */

    .ios body {
        --ion-background-color: #fbfbfb;
        --ion-background-color-rgb: 0, 0, 0;

        --ion-text-color: #373433;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;

        --ion-toolbar-background: #0d0d0d;

        --ion-item-background: var(--background);
    }

    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

    .md body {
        --ion-background-color: #fbfbfb;
        --ion-background-color-rgb: 18, 18, 18;

        --ion-text-color: #373433;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-border-color: #222;

        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;

        --ion-item-background: var(--background);

        --ion-toolbar-background: #1f1f1f;

        --ion-tab-bar-background: #1f1f1f;
    }
}

.uproot-text {
    font-size: 1rem;
    color: var(--ion-text-color);
}

.uproot-h2 {
    font-size: 2rem;
    font-weight: 700;
}

.uproot-h3 {
    font-size: 1.5rem;
}

.uproot-h6 {
    font-weight: bold;
}

.clickLink {
    display: block;
    margin-top: 1rem;
    color: var(--ion-color-primary);
    font-weight: bold;
    cursor: pointer;
}
.clicklink:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 48rem) {
    .uproot-h2 {
        font-size: 1.75rem;
    }

    .uproot-h3 {
        font-size: 1.25rem;
    }
}
