.deleteProfileForm {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: center;
}

.yesButton {
    margin-right: 0.5rem;
}
