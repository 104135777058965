@import '../../theme/helper.scss';

.headerContainer {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 6.25rem;
    background: var(--ion-color-tertiary);
    box-shadow: 0 0.25rem 0.875rem var(--ion-color-light-shade);

    @mixin headerContainer-responsive {
        min-height: unset;

        &.isMobileSticky {
            position: fixed;
            /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
        }
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include headerContainer-responsive;
    }

    &-mobile {
        @include headerContainer-responsive;
    }

    .headerTitle {
        margin: 1.5rem 0 0;
        font-weight: bold;
        color: var(--ion-text-color);

        @media only screen and (min-width: $mobile-breakpoint) {
            margin-top: 0.6875;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            display: none;
        }

        &-mobile {
            display: none;
        }
    }
}
