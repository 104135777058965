.btn {
    text-transform: none;
    font-weight: bold;
    font-size: 0.85rem;
    letter-spacing: 0;
    min-width: 12rem;
    max-width: 18.75rem;
    height: auto;

    --box-shadow: none;
    --padding-top: 1.1rem;
    --padding-bottom: 1rem;

    &.primary {
        --color: var(--ion-color-primary-contrast);
    }

    /*
        None needed for now
        &.secondary {
            // ..
        }
    */

    &.tertiary {
        &::part(native) {
            height: 2.9rem;
        }

        --border-color: var(--ion-color-tertiary-contrast);
        --border-style: solid;
        --border-width: 0.0625rem;
    }

    &[size='small'] {
        min-width: 6.5rem;

        --padding-top: 0.75rem;
        --padding-bottom: 0.7rem;

        &::part(native) {
            height: auto;
            padding-left: 1.4rem;
            padding-right: 1.4rem;
        }
    }

    .alignment {
        display: flex;
        align-items: center;
    }

    .capitalized {
        text-transform: capitalize;
    }
}
