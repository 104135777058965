.errorMessage {
    background-color: var(--ion-color-danger-contrast);
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 2rem;
    color: var(--ion-color-danger-shade);
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
}
