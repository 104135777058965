@import '../../theme/helper.scss';

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background: var(--ion-color-light-shade);

    @mixin container-responsive {
        align-items: flex-start;
        height: 100%;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include container-responsive;
    }

    &-mobile {
        @include container-responsive;
    }
}

.main {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    direction: rtl;
    width: 100%;

    @mixin main-responsive {
        flex-direction: column;
        width: 100%;
        min-width: auto;
        direction: ltr;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include main-responsive;
    }

    &-mobile {
        @include main-responsive;
    }
}

.headingSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    height: 50%;
    padding-left: $padding;
    padding-right: $padding;
    background: var(--ion-color-tertiary-tint);
    direction: ltr;

    @mixin headingSection-responsive {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include headingSection-responsive;
    }

    &-mobile {
        @include headingSection-responsive;
    }
}

.headingHeader {
    margin: 0 0 2.25rem;
    color: var(--ion-color-step-250);
    font-weight: bold;

    @mixin headingHeader-responsive {
        margin-top: 2rem;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include headingHeader-responsive;
    }

    &-mobile {
        @include headingHeader-responsive;
    }
}

.headingBody {
    margin: 0;
    line-height: 1.5em;

    @mixin headingBody-responsive {
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include headingBody-responsive;
    }

    &-mobile {
        @include headingBody-responsive;
    }
}

.imagerySection {
    display: flex;
    position: absolute;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100%;
    overflow: hidden;
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    direction: ltr;

    @mixin imagerySection-responsive {
        position: relative;
        width: 100%;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include imagerySection-responsive;
    }

    &-mobile {
        @include imagerySection-responsive;
    }
}

.imageryHeader {
    position: absolute;
    z-index: 2;
    top: 0.8rem;
    left: 4.3rem;
    margin: 0;
    color: var(--ion-color-primary-contrast);
    font-size: 2.25rem;
    font-weight: bold;

    @mixin imageryHeader-responsive {
        display: none;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include imageryHeader-responsive;
    }

    &-mobile {
        @include imageryHeader-responsive;
    }
}

.imageryBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imageryBody {
    z-index: 2;
    width: 50%;
    margin: 3rem 0;
    color: var(--ion-color-primary-contrast);

    @mixin imageryBody-responsive {
        width: 36%;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include imageryBody-responsive;
    }

    &-mobile {
        @include imageryBody-responsive;
    }
}

.callToActionSection {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 60%;
    height: 50%;
    padding: $padding;
    background: var(--ion-color-success-tint);
    color: var(--ion-color-primary-contrast);
    direction: ltr;

    @mixin callToActionSection-responsive {
        width: 100%;
        padding: $padding 1rem;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include callToActionSection-responsive;
    }

    &-mobile {
        @include callToActionSection-responsive;
    }
}

.toggleIcon {
    border-radius: 50%;
    color: rgb(79, 75, 74);
    background-color: white;
    text-align: center;
    z-index: 1000;
    cursor: pointer;
}

.callToActionHeader {
    margin: 0 0 4rem;
    font-weight: bold;
}

.callToActionForm {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    & > * {
        flex-basis: 30%;

        &:first-child {
            flex-basis: 70%;
            max-width: 22rem;
            margin-bottom: 1rem;
        }
    }

    .addressAutocompleteWrapper {
        font-size: 1.25rem;
        border-bottom: 0.125rem solid var(--ion-color-secondary);
        border-radius: 0.25rem;

        @media only screen and (max-width: $mobile-breakpoint) {
            width: 100%;
        }
    }

    @mixin callToActionForm-responsive {
        flex-direction: column;
        align-items: center;
        margin: 2.2rem 0;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include callToActionForm-responsive;
    }

    &-mobile {
        @include callToActionForm-responsive;
    }
}

.getStartedCTA {
    margin-bottom: 1.1875rem;
    margin-left: 2em;
}
