@import '../../theme/helper.scss';

.footer {
    color: var(--ion-color-light-tint);
    font-size: 0.875rem;
    background-color: var(--ion-color-secondary-contrast);
    padding: 1.75rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.0625rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logoContainer {
        padding: 0 0 1rem 0;

        .logo {
            padding: 0 1rem;
        }
    }

    @mixin footer-responsive {
        padding: 1.25rem;
        font-size: 0.875rem;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include footer-responsive;
    }

    &-mobile {
        @include footer-responsive;
    }
}
