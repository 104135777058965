@import '../../../theme/helper.scss';

.mobileNav {
    display: none;

    @mixin mobileNav-responsive {
        display: flex;
    }

    @media all and (max-width: $mobile-breakpoint) {
        @include mobileNav-responsive;
    }

    &-mobile {
        @include mobileNav-responsive;
    }

    .mobileNavList {
        margin-top: env(safe-area-inset-top);
    }

    .hamburgerMenuIcon {
        color: var(--ion-color-dark-tint);
    }

    .active {
        border-bottom: 0.0625rem solid var(--ion-color-dark-tint);
    }

    .title {
        font-size: 1.4em;
    }

    .item {
        color: var(--ion-color-dark-tint);
        padding: 1rem 0 0.5rem 0;
        margin: 0 1rem;
        text-decoration: none;
        font-size: 1.0625rem;
    }

    .mobileSignUpButton {
        padding: 1rem 0 0.5rem 1.25rem;
        font-weight: normal !important;
        font-size: 1.0625rem;
    }

    .logoContainer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 4rem;
        text-align: center;
    }
}
