.contact {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    margin-right: 2rem;
}

.contactTitle {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 140%;
}

.contactSubtitle {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 150%;
}
