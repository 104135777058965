@import '../../theme/helper.scss';

.leftPaneContainer {
    background-color: var(--ion-color-primary-contrast);
    min-width: 20rem;
}

.rightPaneContainer {
    background-color: var(--ion-color-primary-contrast);
    height: 100vh;
    overflow-y: auto;
    padding: 4rem 3rem;
    box-shadow: 0 0.25rem 0.875rem rgba(var(--ion-box-shadow-rgb), 0.75);

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: $wide-tablet-breakpoint) {
        padding-left: 0;
        padding-right: 0;
        overflow-y: visible;
        height: auto;
    }

    @media only screen and (max-width: $tablet-breakpoint) {
        padding: 0;
    }

    .contentTitle {
        text-transform: uppercase;
        letter-spacing: 0.0625rem;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--ion-text-color);
        padding-bottom: 2rem;

        @media only screen and (max-width: $wide-tablet-breakpoint) {
            padding: 1rem 1.3rem;
        }

        @media only screen and (max-width: $tablet-breakpoint) {
            padding: 1rem;
        }
    }
}
