@import '../../theme/helper.scss';

.container {
    padding: 5% 0;
    background-color: var(--ion-color-tertiary-shade);

    @mixin container-responsive {
        padding: 1rem 0;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
        @include container-responsive;
    }

    &-mobile {
        @include container-responsive;
    }

    .content {
        display: flex;
        flex-direction: column;
        max-width: 45rem;
        margin: 0 auto;
        font-size: 1rem;
        background-color: var(--ion-color-dark-contrast);
        box-shadow: 0 0.25rem 0.875rem rgba(var(--ion-box-shadow-rgb), 0.75);
        color: var(--ion-text-color);
        border-radius: 0.5rem;
        padding: 1rem 3rem;

        @mixin content-responsive {
            padding: 1rem;
        }

        @media only screen and (max-width: $mobile-breakpoint) {
            @include content-responsive;
        }

        &-mobile {
            @include content-responsive;
        }

        .faq {
            border-bottom: 0.0625rem solid var(--ion-color-light-tint);

            .question {
                margin: 1em 0;
                font-weight: bold;
            }

            .answer {
                padding: 0.25em 1em 1em;
            }

            &:last-child {
                border-bottom: none;
            }

            p {
                margin: 1em 0;

                &:first-child {
                    margin-top: 0;
                }
            }

            /* stylelint-disable selector-type-no-unknown */
            ion-icon {
                color: inherit;
            }
        }
    }
}
