@import '../../theme/helper.scss';

.header {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    .accountHeader {
        text-align: left;
        width: 29rem;
    }
}

.accountForm {
    display: flex;
    flex-direction: column;
    width: 29rem;
    max-width: 80%;
    margin: 0 auto;

    &::after {
        content: '';
        border-bottom: 0.0625rem solid var(--ion-color-light-tint);
        margin-top: 3rem;
    }
}

.input {
    width: 29rem;
    min-width: 16.75rem;
    border: 0.0625rem solid var(--ion-color-medium-shade);
    border-radius: 0.25rem;
    overflow: hidden;
    margin-left: -1rem;
    padding-left: 0.5rem;
    padding-top: 0.65rem;
    padding-bottom: 0.5rem;

    @media only screen and (max-width: 48rem) {
        min-width: 16.25rem;
    }
}

.label {
    font-size: 1rem;
    margin-bottom: -1.3rem;

    @media only screen and (max-width: 48rem) {
        margin-bottom: -0.7rem;
    }
}

span.input-footer-copy {
    font-size: 0.75rem;
    position: relative;
    line-height: 1.5;
    top: 0.18rem;
    display: block;
    margin: 0 0 1.6rem 0;
}
