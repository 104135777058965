@import '../../theme/helper.scss';

.signUpForm {
    display: flex;
    flex-direction: column;

    .label {
        font-size: 1rem;
        margin-bottom: -1.3rem;

        @media only screen and (max-width: $mobile-breakpoint) {
            margin-bottom: -0.7rem;
        }
    }

    .input {
        width: 100%;
        min-width: 16.75rem;
        border: 0.0625rem solid var(--ion-color-medium-shade);
        border-radius: 0.25rem;
        overflow: hidden;
        margin-left: -1rem;
        padding-left: 0.5rem;
        padding-top: 0.65rem;
        padding-bottom: 0.5rem;

        @media only screen and (max-width: $mobile-breakpoint) {
            min-width: 16.25rem;
        }
    }

    &-mobile {
        .input {
            min-width: 16.25rem;
        }
    }
}
