@import '../../../theme/helper.scss';

.container {
    width: 100%;
    height: 8.875rem;
    max-width: 26.25rem;
    display: flex;
    cursor: pointer;

    --background: transparent;
    --padding-start: 1.875rem;
    --padding-top: 1.5625rem;
    --padding-bottom: 1.5625rem;
    --inner-border-width: 0;

    @media only screen and (max-width: $mobile-breakpoint) {
        --padding-start: 0.9375rem;
    }

    .repIcon {
        margin-left: auto;

        .icon {
            font-size: 1.3rem;
        }
    }

    .text {
        margin: 0 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        line-height: 142.5%;

        .name {
            font-size: 1.25rem;
            font-weight: bold;

            @mixin name-responsive {
                font-size: 1rem;
            }

            @media only screen and (max-width: $mobile-breakpoint) {
                @include name-responsive;
            }

            &-mobile {
                @include name-responsive;
            }
        }

        .title {
            font-size: 0.875rem;
        }
    }
}
