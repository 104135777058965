@import '../../../theme/helper.scss';

.accordion {
    color: var(--ion-color-step-250);
    max-width: 60rem;
    border-radius: 0.125rem;
    border-bottom: 0.0625rem solid var(--ion-color-light-shade);
    box-shadow: 0.0625rem 0.0625rem 0.875rem rgba(var(--ion-box-shadow-rgb), 0.75);

    .accordionItem {
        border-top: 0.0625rem solid rgba(var(--ion-color-light-contrast-rgb), 0.1);

        .accordionItemButton {
            position: relative;
            cursor: pointer;
            padding: 1.875rem 2.1875rem 1.875rem 1.5625rem;
            width: 100%;
            text-align: left;
            font-weight: 600;
            font-size: 1.1rem;
            box-shadow: inset -0.0625rem rgba(var(--ion-color-light-contrast-rgb), 0.1);

            @mixin accordionItemButton-responsive {
                padding: 1.5625rem 1.5625rem 1.5625rem 0.9375rem;
            }

            @media only screen and (max-width: $mobile-breakpoint) {
                @include accordionItemButton-responsive;
            }

            &-mobile {
                @include accordionItemButton-responsive;

                &::after {
                    right: 0.8rem;
                }
            }

            &::after {
                position: absolute;
                display: flex;
                align-items: center;
                top: 0;
                bottom: 0;
                right: 1.2rem;
                content: '+';
                font-weight: 100;
                font-size: 1.5625rem;
                line-height: 1.4375rem;
                color: var(--ion-color-medium-shade);

                @media only screen and (max-width: $mobile-breakpoint) {
                    right: 0.8rem;
                }
            }

            &[aria-expanded='true'],
            &[aria-selected='true'] {
                box-shadow: inset -0.3125rem 0 0 0 var(--ion-color-primary);

                &::after {
                    content: '-';
                }
            }
        }

        .accordionPanel {
            padding: 1.5625rem;
            border-top: 0.0625rem solid rgba(var(--ion-color-light-contrast-rgb), 0.1);
            background-color: var(--ion-color-tertiary);

            @mixin accordionPanel-responsive {
                padding: 0.9375rem;
            }

            @media only screen and (max-width: $mobile-breakpoint) {
                @include accordionPanel-responsive;
            }

            &-mobile {
                @include accordionPanel-responsive;
            }
        }
    }

    [hidden] {
        display: none;
    }
}
