@import '../../theme/helper.scss';

.contentWrapper {
    min-height: calc(100vh - 3.5625rem);
    background: var(--ion-color-tertiary-shade);
    margin-top: env(safe-area-inset-top);

    &.withTopSpacing {
        padding-top: 3.4375rem;

        @media only screen and (max-width: $mobile-breakpoint) {
            padding-top: 2.75rem;
        }
    }

    &.withBottomSpacing {
        padding-bottom: 6rem;

        @media only screen and (max-width: $mobile-breakpoint) {
            padding-bottom: 4rem;
        }
    }

    &-mobile {
        &.withTopSpacing {
            padding-top: 2.75rem;
        }

        &.withBottomSpacing {
            padding-bottom: 4rem;
        }
    }
}
