/* Core CSS required for Ionic components to work properly */
@import '../node_modules/@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '../node_modules/@ionic/react/css/normalize.css';
@import '../node_modules/@ionic/react/css/structure.css';
@import '../node_modules/@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '../node_modules/@ionic/react/css/padding.css';
@import '../node_modules/@ionic/react/css/float-elements.css';
@import '../node_modules/@ionic/react/css/text-alignment.css';
@import '../node_modules/@ionic/react/css/text-transformation.css';
@import '../node_modules/@ionic/react/css/flex-utils.css';
@import '../node_modules/@ionic/react/css/display.css';

/* Theme variables */
@import './theme/variables.css';

/* CSS reset margin and padding */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
